import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useUser } from './contexts/UserContext';
import RecipeManagement from './components/RecipeManagement';
import Login from './components/Login';
import Register from './components/Register';

function App() {
  const { user } = useUser();

  return (
    <div className="min-h-screen bg-white text-text">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/recipes"
          element={user ? <RecipeManagement /> : <Navigate to="/login" replace />}
        />
        <Route path="/" element={<Navigate to="/recipes" replace />} />
      </Routes>
    </div>
  );
}

export default App;