import React, { useState } from 'react';
import { useRecipe } from '../contexts/RecipeContext';

const RecipeManagement: React.FC = () => {
  const { recipes, addRecipe } = useRecipe();
  const [newRecipeName, setNewRecipeName] = useState('');

  const handleAddRecipe = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newRecipeName.trim()) {
      try {
        await addRecipe({
          name: newRecipeName,
          ingredients: [],
          instructions: '',
          favorite: false,
        });
        setNewRecipeName('');
      } catch (error) {
        console.error('Error adding recipe:', error);
      }
    }
  };

  return (
    <div>
      <h2>Recipe Management</h2>
      <form onSubmit={handleAddRecipe}>
        <input
          type="text"
          value={newRecipeName}
          onChange={(e) => setNewRecipeName(e.target.value)}
          placeholder="New recipe name"
        />
        <button type="submit">Add Recipe</button>
      </form>
      <ul>
        {recipes.map((recipe) => (
          <li key={recipe.id}>{recipe.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default RecipeManagement;