import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCYAkOsnWJVJNFNuPpRuFe4In6hSo1b5TA",
  authDomain: "mealmaster-3f01e.firebaseapp.com",
  projectId: "mealmaster-3f01e",
  storageBucket: "mealmaster-3f01e.appspot.com",
  messagingSenderId: "404185505978",
  appId: "1:404185505978:web:67a591d1375f696995e283",
  measurementId: "G-40P6QKQGQS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export the auth and db instances
export const auth = getAuth(app);
export const db = getFirestore(app);

// Enable offline persistence
if (process.env.NODE_ENV !== 'development') {
  enableIndexedDbPersistence(db).catch((err) => {
    if (err.code == 'failed-precondition') {
      console.log('Multiple tabs open, persistence can only be enabled in one tab at a a time.');
    } else if (err.code == 'unimplemented') {
      console.log('The current browser does not support all of the features required to enable persistence');
    }
  });
}

// Use emulators if in development environment
if (process.env.NODE_ENV === 'development') {
  // Import these only if in development to avoid bundling them in production
  import('firebase/auth').then(({ connectAuthEmulator }) => {
    connectAuthEmulator(auth, 'http://localhost:9099');
  });
  import('firebase/firestore').then(({ connectFirestoreEmulator }) => {
    connectFirestoreEmulator(db, 'localhost', 8080);
  });
}