import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { 
  User as FirebaseUser, 
  GoogleAuthProvider, 
  signInWithPopup,
  signInWithRedirect, 
  getRedirectResult,
  signOut, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  updateProfile,
  deleteUser
} from 'firebase/auth';
import { doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';

interface User {
  id: string;
  username: string;
  email: string;
}

interface UserContextType {
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  loginWithGoogle: () => Promise<void>;
  logout: () => Promise<void>;
  register: (username: string, email: string, password: string) => Promise<void>;
  changePassword: (currentPassword: string, newPassword: string) => Promise<void>;
  deleteAccount: () => Promise<void>;
  updateUserProfile: (data: Partial<User>) => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
          if (userDoc.exists()) {
            setUser({ id: firebaseUser.uid, ...userDoc.data() } as User);
          } else {
            // If the user document doesn't exist, create it
            const newUser = {
              id: firebaseUser.uid,
              username: firebaseUser.displayName || '',
              email: firebaseUser.email || '',
            };
            await setDoc(doc(db, 'users', firebaseUser.uid), newUser);
            setUser(newUser);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          // Set basic user info from Firebase Auth if Firestore is unavailable
          setUser({
            id: firebaseUser.uid,
            username: firebaseUser.displayName || '',
            email: firebaseUser.email || '',
          });
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
      if (userDoc.exists()) {
        setUser({ id: userCredential.user.uid, ...userDoc.data() } as User);
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const { user: firebaseUser } = result;
      try {
        const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
        if (!userDoc.exists()) {
          const newUser = {
            id: firebaseUser.uid,
            username: firebaseUser.displayName || '',
            email: firebaseUser.email || '',
          };
          await setDoc(doc(db, 'users', firebaseUser.uid), newUser);
        }
        setUser({ id: firebaseUser.uid, ...userDoc.data() } as User);
      } catch (error) {
        console.error('Error accessing Firestore:', error);
        // Set basic user info from Google Auth if Firestore is unavailable
        setUser({
          id: firebaseUser.uid,
          username: firebaseUser.displayName || '',
          email: firebaseUser.email || '',
        });
      }
    } catch (error: any) {
      console.error("Google Sign-In Error:", error);
      if (error.code === 'auth/popup-blocked') {
        try {
          await signInWithRedirect(auth, provider);
          // The result will be handled in the useEffect hook
        } catch (redirectError) {
          console.error("Redirect Sign-In Error:", redirectError);
          throw redirectError;
        }
      } else if (error.code === 'auth/unauthorized-domain') {
        console.error("Unauthorized domain. Please add this domain to your Firebase project's authorized domains.");
        // You might want to show a user-friendly error message here
      } else {
        throw error;
      }
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  };

  const register = async (username: string, email: string, password: string) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const newUser = { id: userCredential.user.uid, username, email };
      await setDoc(doc(db, 'users', userCredential.user.uid), newUser);
      setUser(newUser);
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  };

  const changePassword = async (currentPassword: string, newPassword: string) => {
    // Implement password change logic here
    console.log('Change password functionality to be implemented');
  };

  const deleteAccount = async () => {
    if (auth.currentUser) {
      try {
        await deleteDoc(doc(db, 'users', auth.currentUser.uid));
        await deleteUser(auth.currentUser);
        setUser(null);
      } catch (error) {
        console.error('Delete account error:', error);
        throw error;
      }
    }
  };

  const updateUserProfile = async (data: Partial<User>) => {
    if (auth.currentUser) {
      try {
        await setDoc(doc(db, 'users', auth.currentUser.uid), data, { merge: true });
        if (data.username) {
          await updateProfile(auth.currentUser, { displayName: data.username });
        }
        setUser(prev => prev ? { ...prev, ...data } : null);
      } catch (error) {
        console.error('Update profile error:', error);
        throw error;
      }
    }
  };

  const contextValue: UserContextType = {
    user,
    login,
    loginWithGoogle,
    logout,
    register,
    changePassword,
    deleteAccount,
    updateUserProfile,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};