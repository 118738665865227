import React, { createContext, useState, useContext, ReactNode, useEffect, useCallback } from 'react';
import { useUser } from './UserContext';
import { db } from '../firebase';
import { collection, doc, setDoc, getDocs } from 'firebase/firestore';

export interface Recipe {
  id: string;
  name: string;
  ingredients: string[];
  instructions: string;
  favorite: boolean;
}

interface RecipeContextType {
  recipes: Recipe[];
  addRecipe: (recipe: Omit<Recipe, 'id'>) => Promise<void>;
}

const RecipeContext = createContext<RecipeContextType | undefined>(undefined);

export const RecipeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useUser();
  const [recipes, setRecipes] = useState<Recipe[]>([]);

  useEffect(() => {
    if (user) {
      fetchRecipes();
    } else {
      setRecipes([]);
    }
  }, [user]);

  const fetchRecipes = useCallback(async () => {
    if (!user) return;
    try {
      const recipesRef = collection(db, `users/${user.id}/recipes`);
      const recipesSnapshot = await getDocs(recipesRef);
      const fetchedRecipes: Recipe[] = recipesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Recipe));
      setRecipes(fetchedRecipes);
    } catch (error) {
      console.error('Error fetching recipes:', error);
    }
  }, [user]);

  const addRecipe = useCallback(async (recipe: Omit<Recipe, 'id'>) => {
    if (!user) throw new Error('User not authenticated');
    try {
      const newRecipeRef = doc(collection(db, `users/${user.id}/recipes`));
      const newRecipe: Recipe = {
        ...recipe,
        id: newRecipeRef.id,
      };
      await setDoc(newRecipeRef, newRecipe);
      setRecipes(prevRecipes => [...prevRecipes, newRecipe]);
    } catch (error) {
      console.error('Error adding recipe:', error);
      throw error;
    }
  }, [user]);

  return (
    <RecipeContext.Provider value={{ recipes, addRecipe }}>
      {children}
    </RecipeContext.Provider>
  );
};

export const useRecipe = () => {
  const context = useContext(RecipeContext);
  if (context === undefined) {
    throw new Error('useRecipe must be used within a RecipeProvider');
  }
  return context;
};