import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App.tsx'
import './index.css'
import { UserProvider } from './contexts/UserContext'
import { RecipeProvider } from './contexts/RecipeContext'
import ErrorBoundary from './components/ErrorBoundary'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ErrorBoundary>
      <UserProvider>
        <RecipeProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </RecipeProvider>
      </UserProvider>
    </ErrorBoundary>
  </React.StrictMode>,
)